
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HotelComponent',
  props: {
    id: {
      required: true,
      type: Number,
      default: 0,
    },
    name: {
      required: true,
      default: 'John doe',
      type: String,
    },
    image: {
      required: true,
      default: 'John doe',
      type: String,
    },
    price: {
      required: true,
      type: Number,
      default: 0,
    },
    weekday: {
      required: true,
      type: Boolean,
      default: true,
    },
    rewards: {
      required: true,
      type: Boolean,
      default: true,
    },
    rating: {
      required: true,
      type: String,
      default: '0.0',
    },
  },
});
